import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useRef } from 'react';

import FeatureSlider from '../components/FeatureSlider';

export default function FeaturesPage() {
  const theme = useTheme();
  const featureSliderRef = useRef<HTMLDivElement | null>(null);


  const handleScrollToFeatures = () => {
    if (featureSliderRef.current) {
      featureSliderRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ bgcolor: theme.palette.background.default }}>


      {/* Overview */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          bgcolor: 'rgba(0,0,0,0.8)',
          color: 'white',
          py: 10,
          minHeight: 'calc(100vh - 80px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 10,
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '50%' },
              textAlign: { xs: 'center', md: 'left' },
              mb: { xs: 5, md: 0 },
            }}
          >
            <Typography variant="h4" gutterBottom>
              Computational Mesh Morphing Software
            </Typography>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Developed by the Air Force. Licensed for commercial use.
            </Typography>
            <Box
              component="ul"
              sx={{
                listStyleType: 'disc',
                pl: 4,
                mb: 4,
              }}
            >
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Compatible with commercial FEA meshes (Ansys, Siemens NX, etc.).
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Handles large surface scans (100M+ points).
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Processes dense models with 10M+ nodes effortlessly.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Fully automated and robust part registration.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Journaling and scripting for workflow automation.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Comprehensive APIs for integration.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1 }} style={{ textAlign: 'left' }}>
                Advanced Boolean operations for precise geometry edits.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleScrollToFeatures}
            >              Explore
            </Button>
          </Box>
          <Box
            component="img"
            src="/features/application-laptop.webp"
            alt="Overview Image"
            sx={{
              width: { xs: '100%', md: '50%' },
              height: 'auto',
              borderRadius: 2,
              boxShadow: theme.shadows[3],
            }}
          />
        </Box>
      </Box>

      {/* <Box sx={{ width: '100%', height: '1px', bgcolor: theme.palette.divider, my: 5 }} /> */}

      {/* Feature Slider Section */}
      <Box ref={featureSliderRef}>

        <FeatureSlider />
      </Box>

    </Box>
  );
}
