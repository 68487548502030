import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';

const PrattCaseStudy: React.FC = () => {
  return (
    <Box mt={8} mb={8} p={4} style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
      <Typography variant="h4" py={1} align="center" gutterBottom>
        FEMORPH Case Study: Usage at Pratt & Whitney 
      </Typography>

      <Typography variant="h5" py={1} align="center" gutterBottom>
        Improving Manufacturing Yield through As-Manufactured Modeling
      </Typography>

      <Box sx={{ float: 'right', marginLeft: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/pt6a_cross_section.webp"
          alt="PT6A Cross Section"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
      </Box>

      <Typography variant="body1" paragraph>
        In April 2018, the Air Force Research Laboratory (
        <a
          href="https://www.afrl.af.mil/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          AFRL
        </a>
        ) licensed
        FEMORPH to Pratt & Whitney and United
        Technologies Corporation. This marked the first use of the Department
        of Defense's software licensing authority under Section 801 of the 2014
        National Defense Authorization Act. {' ' }
        <a
          href="https://www.prattwhitney.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Pratt & Whitney
        </a>
        { ' '} utializes FEMORPH within the Airfoil 2.0 initiative to improve
        part performance at lower cost by incorporating as-manufactured models
        in the design, manufacturing, and maintenance processes of engine
        components. This software helps compute the the actual performance of
        real-world components by taking the actual part geometry and updating
        the original as-designed finite element models. These digital twins are
        used both within commercial and military programs.
      </Typography>

      <Typography variant="body1" paragraph>
        FEMORPH, enables the automatic creation of precise as-manufactured finite element models with 
        geometry collected through component measuring machines or full field 3D scans collected through hardware like {' '}
        <a
          href="https://www.capture3d.com/3d-metrology-solutions/3d-scanners/atos-5"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Capture 3D's ATOS Scanner
        </a>
        . The software accurately models any geometric deviations that may arise
        during production by allowing for the analysis of as-manufactured models through commercial finite element software like {' '}
        <a
          href="https://www.ansys.com/products/structures/ansys-mechanical"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Ansys Mechanical
        </a>
        {' '} or {' '}
        <a
          href="https://plm.sw.siemens.com/en-US/simcenter/mechanical-simulation/nastran/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#3f51b5' }}
        >
          Siemens Nastran
        </a>
        . FEMORPH has plugins that enable file based integration for
        programmatic scripting and full end-to-end automation of digital twin
        based analysis of as-produced hardware.
        </Typography>

      <Box sx={{ float: 'left', marginRight: 2, marginTop: 1, width: '300px' }}>
        <img
          src="/case_studies/atos-5-airfoil.webp"
          alt="PT6A Cross Section"
          style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
        />
      </Box>

      <Typography variant="body1" paragraph>
        This capability allows for the acceptance or rejection of parts based
        on their as-manufactured performance rather than merely their geometric
        tolerances. FEMORPH's commercial application extends beyond improving process
        yield and  reducing manufacturing costs; it's been incorporated into improving the service life of
        critical hardware through advanced repair strategies. Its impact is
        transformative, directly supporting Pratt & Whitney’s mission to
        simultaneously optimize engine performance while raising production
        yields at its Columbus Forge.
      </Typography>

      <Typography>
        FEMORPH is now on its 7th year of continuous usage within Pratt & Whitney and its usage continues to grow as it is applied to:
      </Typography>

      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside'
        }}
      >
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify' }}>
          CAD based morphing: Topolgy preserving FEM to CAD
          metamorphosis. FEMORPH outputs topologically identical models
          matching parametrically generated CAD from a design of experiments.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify' }}>
          Damaged airfoil simulation: Uses FEMORPH's modular software approach
          to automatically generate blends on the surfaces of airfoils to
          simulate a variety of blend depth to optimize valid blends before
          generating them.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }} style={{ textAlign: 'justify' }}>
          Broad model support: FEMORPH has built-in compatibility for inserted
          blade, integrally bladed rotors, hollow turbine blades, and gas
          turbines disks.
        </ListItem>
      </List>

      <Typography variant="body1" paragraph>
        FEMORPH continues to be developed to address the evolving needs of the
        Pratt & Whitney. Future enhancements aim to incorporate machine
        learning algorithms for predictive modeling, expand compatibility with
        next-generation CAD and simulation tools, and streamline workflows to
        reduce design-to-production cycle times. As Pratt & Whitney continues
        to push the boundaries of turbomachinery innovation, FEMORPH will
        remain a critical technology in the aerospace sector, enabling
        unparalleled precision, cost efficiency, and reliability in
        manufacturing and repair processes.
      </Typography>

    </Box>


  );
};

export default PrattCaseStudy;
