import { Box, Typography, Button, Grid } from '@mui/material';
import Marquee from 'react-fast-marquee';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export default function MainPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const logos = [
    { src: '/assets/afrl_logo.webp', link: 'https://www.afrl.af.mil/' },
    { src: '/assets/pratt_whitney_logo.webp', link: 'https://www.prattwhitney.com/' },
    { src: '/assets/nasa_logo.svg', link: 'https://www.nasa.gov/' },
    { src:'/assets/kratos_logo.webp', link: 'https://www.kratosdefense.com/' },
    { src:'/assets/ud_logo.webp', link: 'https://udayton.edu/' },
    { src:'/assets/Williams_International_logo.svg', link: 'https://www.williams-int.com/' },
    // { src:'/assets/GE_Aerospace_logo.svg.png', link: 'https://www.geaerospace.com/' },
    { src:'/assets/beehive_logo.jpg', link: 'https://www.beehive-industries.com/' },
    { src:'/assets/wright-state-logo.webp', link: 'https://www.wright.edu/' },
    { src:'/assets/apex_logo.png', link: 'https://apexturbine.com/' },
    { src:'/assets/naval_air_systems_command.webp', link: 'https://www.navair.navy.mil/' },
    { src:'/assets/aflcmc_logo.webp', link: 'https://www.aflcmc.af.mil/' },
    { src:'/assets/Optomec-formal-logo.webp', link: 'https://optomec.com/' },
    { src:'/assets/notre-dame-logo.png', link: 'https://www.nd.edu/' },
  ];

  return (
    <Box sx={{ p: 0, overflow: 'hidden' }}>

      {/* Hero */}
      <Box sx={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden', bgcolor: '#f7f7f7' }}>
        <Box sx={{ position: 'absolute', top:0, left:0, width:'100%', height:'100%', bgcolor:'rgba(0,0,0,0.5)', zIndex:1 }} />
        <Box sx={{ position:'absolute', top:0, left:0, width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', zIndex:2 }}>
          <Box sx={{ width:'700px', textAlign:'center' }}>
            <Typography variant="h2" color="white" gutterBottom>Transform 3D Scans into Exact Digital Twins</Typography>
            <Typography variant="h6" color="white" sx={{ my:4 }}>
              Seamlessly convert as-manufactured geometry into precise finite element models with FEMORPH.
            </Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/features')} size='large'>
              Explore
            </Button>
          </Box>
        </Box>
        <Box
          component="img"
          src="/main-page-dark.webp"
          alt="Background"
          sx={{ position:'absolute', top:0, left:0, width:'100%', height:'100%', objectFit:'cover', zIndex:0 }}
        />
      </Box>

      {/* Demo */}
      <Box sx={{ bgcolor:'#fff', p: 10 }}>
        <Grid container spacing={2} sx={{ alignItems:'center' }}>
          <Grid item xs={12} md={6}>
            <Box
              component="video"
              src="/turbine-morph-w-opacity-60fps-extended-1066x532.mp4"
              autoPlay
              loop
              muted
              playsInline
              sx={{ width:'100%', height:'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ textAlign:'center' }}>
              Transform Your As-Designed Models Into As-Manufactured Reality
            </Typography>
            <Typography variant="body1" sx={{ mt:2, textAlign:'center' }}>
              Achieve market-leading precision and accuracy with our advanced morphing engine.
            </Typography>
          </Grid>
        </Grid>
      </Box>



      {/* Marketing Sections */}
      {/* Section 1 */}
      <Box sx={{ bgcolor: '#f0f0f0', p: 10 }}>
        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              Precision Digital Twins for Complex Systems
            </Typography>
            <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              FEMORPH redefines accuracy in digital twin creation, transforming raw 3D scans into exact replicas of as-manufactured components. By aligning designs with reality, FEMORPH ensures your computational analyses provide a robust foundation for reliable analysis and optimized production.
            </Typography>
            <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
              <Button variant="contained" color="primary" onClick={() => navigate('/case-studies')}>
                Learn More
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/iStock-874157420.webp"
              alt="Digital Twin Illustration"
              sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Section 2 */}
      <Box sx={{ bgcolor: '#fff', p: 10 }}>
        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/iStock-1160415389.webp"
              alt="Maintenance Workflow Illustration"
              sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              Streamlining Maintenance and Repairs
            </Typography>
            <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              FEMORPH integrates seamlessly into repair workflows, enabling rapid analysis and optimization of mission-critical components. It reduces downtime, extends part life, and enhances operational readiness.
            </Typography>
            <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
              <Button variant="contained" color="primary" onClick={() => navigate('/features')}>
                Discover How
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Section 3 */}
      <Box sx={{ bgcolor: '#f0f0f0', p: 10 }}>
        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              Scalable Solutions for Advanced Manufacturing
            </Typography>
            <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 4 }}>
              FEMORPH delivers scalable solutions for complex manufacturing needs, supporting alignment, remeshing, and mesh refinements. It drives innovation and ensures quality in high-precision production environments.
            </Typography>
            <Box sx={{ textAlign: { xs: 'center', md: 'center' } }}>
              <Button variant="contained" color="primary" onClick={() => navigate('/features')}>
                Explore More
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/iStock-1219894368.webp"
              alt="Advanced Manufacturing Illustration"
              sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
            />
          </Grid>
        </Grid>
      </Box>


      {/* Logos */}
      <Box sx={{ bgcolor:'#fff', py:10 }}>
        <Typography variant="h3" sx={{ textAlign:'center', mb:4 }}>
          Trusted by Industry Leaders
        </Typography>
        <Marquee gradient={true} gradientColor={theme.palette.mode==='dark'?[0,0,0]:[255,255,255]} speed={40}>
          {logos.map((logo, i) => (
            <Box key={i} sx={{ mx:4, mt: 4}}>
              <a href={logo.link} target="_blank" rel="noopener noreferrer">
                <img src={logo.src} alt={`logo-${i}`} style={{ height:'80px', objectFit:'contain' }} />
              </a>
            </Box>
          ))}
        </Marquee>
      </Box>

    </Box>
  );
}
