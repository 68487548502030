import { IconButton, Drawer } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";

const Header: React.FC = () => {
  const { isAuthenticated, logoutUser } = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:900px)');

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/');
    toggleDrawer(false); // Ensure drawer closes on logout
  };

  const renderDrawerContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
      <Button color="inherit" component={Link} to="/features" onClick={() => toggleDrawer(false)}>
        Features
      </Button>
      <Button color="inherit" component={Link} to="/case-studies" onClick={() => toggleDrawer(false)}>
        Case Studies
      </Button>
      <Button color="inherit" href="/docs" onClick={() => toggleDrawer(false)}>
        Documentation
      </Button>
      <Button color="inherit" component={Link} to="/downloads" onClick={() => toggleDrawer(false)}>
        Downloads
      </Button>
      <Button color="inherit" component={Link} to="/support" onClick={() => toggleDrawer(false)}>
        Support
      </Button>
      <Button color="inherit" component={Link} to="/account" onClick={() => toggleDrawer(false)}>
        Account
      </Button>
      {isAuthenticated ? (
        <Button variant="outlined" sx={{ mt: 'auto' }} onClick={handleLogout}>
          Logout
        </Button>
      ) : (
        <Button
          variant="outlined"
          sx={{ mt: 'auto' }}
          onClick={() => {
            toggleDrawer(false);
            navigate('/login');
          }}
        >
          Login
        </Button>
      )}
    </Box>
  );

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Link
          to="/"
          style={{
            height: 80,
            display: 'flex',
            alignItems: 'center',
            color: 'inherit',
            textDecoration: 'none',
            flexGrow: 1,
          }}
        >
          <img
            className="logo"
            src="/logo192.png"
            alt="Logo"
            style={{ height: 60, marginRight: '10px' }}
          />
          <Typography sx={{ px: 1 }} variant="h5" color="inherit" noWrap>
            FEMORPH
          </Typography>
        </Link>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => toggleDrawer(true)}
          sx={{ mr: 2, display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {!isMobile && (
          <>
            <Button color="inherit" component={Link} to="/features">
              Features
            </Button>
            <Button color="inherit" component={Link} to="/case-studies">
              Case Studies
            </Button>
            <Button color="inherit" href="/docs">
              Documentation
            </Button>
            <Button color="inherit" component={Link} to="/downloads">
              Downloads
            </Button>
            <Button color="inherit" component={Link} to="/support">
              Support
            </Button>
            <Button color="inherit" component={Link} to="/account">
              Account
            </Button>
            {isAuthenticated ? (
              <Button variant="outlined" sx={{ m: 1, width: 0.06 }} onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{ m: 1, width: 0.06 }}
                onClick={() => navigate('/login')}
              >
                Login
              </Button>
            )}
          </>
        )}
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        {renderDrawerContent}
      </Drawer>
    </AppBar>
  );
};

export default Header;
