import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Sla: React.FC = () => {
  const [sections, setSections] = useState<any[]>([]);
  const [references, setReferences] = useState<{ [key: string]: string }>({}); // To store reference mappings

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/sla.json'); // Replace with your JSON file path
        const jsonData = await response.json();
        const computedReferences = preprocessReferences(jsonData);
        setReferences(computedReferences); // Store computed references
        setSections(jsonData); // Store the sections for rendering
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };
    fetchData();
  }, []);

  const generateSectionNumber = (sectionIndex: number, contentIndex: number = 0, subpointIndex?: number) => {
    if (subpointIndex !== undefined) {
      return `${sectionIndex + 1}.${contentIndex + 1}.${subpointIndex + 1}`;
    }
    if (contentIndex !== undefined) {
      return `${sectionIndex + 1}.${contentIndex + 1}`;
    }
    return `${sectionIndex + 1}`;
  };

  const preprocessReferences = (data: any[]) => {
    const refs: { [key: string]: string } = {};
    data.forEach((section, sectionIndex) => {
      section.content.forEach((item: any, contentIndex: number) => {
        const number = generateSectionNumber(sectionIndex, contentIndex);

        if (item.label) {
          refs[item.label] = number;
        }

        if (item.subpoints?.length > 0) {
          item.subpoints.forEach((subpoint: any, subpointIndex: number) => {
            const subpointNumber = generateSectionNumber(sectionIndex, contentIndex, subpointIndex);

            if (subpoint.label) {
              refs[subpoint.label] = subpointNumber;
            }
          });
        }
      });
    });
    return refs;
  };

  const resolveReferences = (text: string) => {
    return text.replace(/\\ref\{(.+?)\}/g, (_, ref) => {
      const refNumber = references[ref];
      if (refNumber) {
        return `<a href="#${ref}">${refNumber}</a>`;
      }
      return `<span style="color: red;">[Unresolved: ${ref}]</span>`;
    });
  };

  const renderSubpoints = (sectionIndex: number, contentIndex: number, subpoints: any[]) => {
    return subpoints.map((subpoint, subpointIndex) => {
      const number = generateSectionNumber(sectionIndex, contentIndex, subpointIndex);
      return (
        <Typography
          key={subpointIndex}
          variant="body2"
          paragraph
          style={{ marginLeft: '20px' }}
          dangerouslySetInnerHTML={{ __html: `${number}. ${resolveReferences(subpoint.text)}` }}
        />
      );
    });
  };

  const renderContent = (sectionIndex: number, content: any[]) => {
    return content.map((item, contentIndex) => {
      const number = generateSectionNumber(sectionIndex, contentIndex);
      return (
        <Box key={contentIndex} mb={2}>
          <Typography
            variant="body1"
            paragraph
            id={item.label || undefined}
            dangerouslySetInnerHTML={{ __html: `${number}. ${resolveReferences(item.text)}` }}
          />
          {item.subpoints?.length > 0 && renderSubpoints(sectionIndex, contentIndex, item.subpoints)}
        </Box>
      );
    });
  };

  return (
    <>
      <Typography variant="h4" p={4} style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
        Advanced Engineering Analysis LLC – Software License Agreement (SLA)
      </Typography>
      <Box p={4} mx="auto" style={{ maxWidth: 800, textAlign: 'justify' }}>
        {sections.length > 0 ? (
          sections.map((section, sectionIndex) => {
            const sectionNumber = generateSectionNumber(sectionIndex);
            return (
              <Box key={sectionIndex} mb={4}>
                <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                  {sectionNumber}. {section.title}
                </Typography>
                {renderContent(sectionIndex, section.content)}
              </Box>
            );
          })
        ) : (
          <Typography variant="body1" align="center">
            Loading...
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Sla;
